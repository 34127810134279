import React, { useState } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row, Button, Form } from "react-bootstrap";
import { IoCarSport } from "react-icons/io5";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { playstore, appstore } from "../../assets/images/images";
import { FaLocationArrow, FaMobileAlt } from "react-icons/fa";
import { FaLocationDot, FaRegCircleStop } from "react-icons/fa6";
import { BiSolidDownArrow } from "react-icons/bi";

const countryOptions = [
  { label: "Ind (+91)", value: "+91" },
  { label: "USA (+1)", value: "+1" },
  { label: "Canada (+1)", value: "+1" },
  { label: "UK (+44)", value: "+44" },
];
function Topbar() {
  const [validated, setValidated] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [isMoreCity, setIsMoreCity] = useState(false);
  const navigate = useNavigate();

  const handlePickupCityChange = (event) => {
    setPickupCity(event.target.value);
    updateMoreCity(event.target.value, destinationCity);
  };

  const handleDestinationCityChange = (event) => {
    setDestinationCity(event.target.value);
    updateMoreCity(pickupCity, event.target.value);
  };

  const updateMoreCity = (pickup, destination) => {
    setIsMoreCity(pickup && destination);
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setMobileNumber(value);
      if (value.length === 10) {
        setErrorMessage("");
      }
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || mobileNumber.length !== 10) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setPickupCity("");
      setDestinationCity("");
      setIsMoreCity(false);
      setErrorMessage("The number should be 10 digits long.");
    } else {
      setValidated(true);
      navigate("/Prices");
    }
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  return (
    <Row className="register">
      <Col sm={12}>
        <Container>
          <Row>
            <Col xl={5} lg={6} md={12}>
              <div className="cab">
                <IoCarSport className="me-2 fs-3" />
                Cabs
              </div>

              <Card className="mt-2 register_box">
                <Card.Body className="p-0">
                  <Card.Title className="p-2 fs-6 text-center">
                    All India Cab Service
                  </Card.Title>
                  <div className="tabs_inner">
                    <Tabs className="first_tab">
                      <TabList>
                        <Tab>Outstation</Tab>
                        <Tab>Local / Airport</Tab>
                      </TabList>

                      <TabPanel>
                        <Tabs>
                          <TabList>
                            <Tab>Round Trip</Tab>
                            <Tab>One Way Trip</Tab>
                          </TabList>

                          <TabPanel>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={handleSubmit}
                            >
                              <Form.Group
                                className="mb-2"
                                controlId="pickup_city"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter pickup city"
                                  value={pickupCity}
                                  onChange={handlePickupCityChange}
                                  required
                                />
                                <FaLocationArrow />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter pickup city
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                controlId="destination"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter destination city"
                                  value={destinationCity}
                                  onChange={handleDestinationCityChange}
                                  required
                                />
                                <FaLocationDot />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Destination city
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-2" controlId="">
                                <Form.Control
                                  type="text"
                                  placeholder="+ Add More City "
                                  disabled={!isMoreCity}
                                />
                                <FaRegCircleStop />
                              </Form.Group>
                              <Form.Group className="mb-2" controlId="mobile">
                                <Select
                                  className="form-select"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  options={countryOptions}
                                  placeholder="In +91"
                                />

                                <Form.Control
                                  className="mobile"
                                  type="text"
                                  placeholder="Enter mobile number"
                                  maxLength="10"
                                  value={mobileNumber}
                                  onChange={handleMobileNumberChange}
                                  required
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Enter Mobile Number
                                </Form.Control.Feedback>
                                {errorMessage && (
                                  <div className="text-danger">
                                    {errorMessage}
                                  </div>
                                )}
                                <FaMobileAlt />
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                className="w-100 search_btn"
                              >
                                Search Cabs
                              </Button>
                            </Form>
                          </TabPanel>
                          <TabPanel>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={handleSubmit}
                            >
                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter pickup city"
                                  value={pickupCity}
                                  onChange={handlePickupCityChange}
                                  required
                                />
                                <FaLocationArrow />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter pickup city
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-2" controlId="">
                                <Form.Control
                                  type="text"
                                  placeholder="Enter destination city"
                                  value={destinationCity}
                                  onChange={handleDestinationCityChange}
                                  required
                                />
                                <FaLocationDot />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Destination city
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Select
                                  className="form-select"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  options={countryOptions}
                                  placeholder="In +91"
                                />

                                <Form.Control
                                  className="mobile"
                                  type="text"
                                  placeholder="Enter mobile number"
                                  maxLength="10"
                                  value={mobileNumber}
                                  onChange={handleMobileNumberChange}
                                  required
                                />
                                <FaMobileAlt />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Mobile Number
                                </Form.Control.Feedback>
                                {errorMessage && (
                                  <div className="text-danger">
                                    {errorMessage}
                                  </div>
                                )}
                              </Form.Group>

                              <Button
                                variant="primary"
                                type="submit"
                                className="w-100 search_btn"
                              >
                                Search Cabs
                              </Button>
                            </Form>
                          </TabPanel>
                        </Tabs>
                      </TabPanel>
                      <TabPanel>
                        <Tabs>
                          <TabList>
                            <Tab>Local Rental</Tab>
                            <Tab>Airport Transfer</Tab>
                          </TabList>

                          <TabPanel>
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={handleSubmit}
                            >
                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Select City"
                                  value={pickupCity}
                                  onChange={handlePickupCityChange}
                                  required
                                />
                                <FaLocationDot />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter pickup city
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group className="mb-2" controlId="">
                                <Form.Control
                                  type="text"
                                  placeholder=" Select package"
                                  value={destinationCity}
                                  onChange={handleDestinationCityChange}
                                  required
                                />
                                <FaLocationDot />
                                <Form.Control.Feedback type="invalid">
                                  Please Select pacakge
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Select
                                  className="form-select"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  options={countryOptions}
                                  placeholder="In +91"
                                />

                                <Form.Control
                                  className="mobile"
                                  type="text"
                                  placeholder="Enter mobile number"
                                  maxLength="10"
                                  value={mobileNumber}
                                  onChange={handleMobileNumberChange}
                                  required
                                />
                                <FaMobileAlt />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Mobile Number
                                </Form.Control.Feedback>
                                {errorMessage && (
                                  <div className="text-danger">
                                    {errorMessage}
                                  </div>
                                )}
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                className="w-100 search_btn"
                              >
                                Search Cabs
                              </Button>
                            </Form>
                          </TabPanel>
                          <TabPanel>
                            <Form noValidate
                              validated={validated}
                              onSubmit={handleSubmit}>
                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="From Airport / To Airport"
                                />
                                <BiSolidDownArrow />
                              </Form.Group>

                              <Form.Group className="mb-2" controlId="">
                                <Form.Control
                                  type="text"
                                  placeholder="Select Airport"
                                />
                                <FaLocationArrow />
                              </Form.Group>

                              <Form.Group
                                className="mb-2"
                                controlId="formBasicEmail"
                              >
                                <Select
                                  className="form-select"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  options={countryOptions}
                                  placeholder="In +91"
                                />

                                <Form.Control
                                  className="mobile"
                                  type="text"
                                  placeholder="Enter mobile number"
                                  maxLength="10"
                                  value={mobileNumber}
                                  onChange={handleMobileNumberChange}
                                  required
                                />
                                <FaMobileAlt />
                                <Form.Control.Feedback type="invalid">
                                  Please Enter Mobile Number
                                </Form.Control.Feedback>
                                {errorMessage && (
                                  <div className="text-danger">
                                    {errorMessage}
                                  </div>
                                )}
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                className="w-100 search_btn"
                              >
                                Search Cabs
                              </Button>
                            </Form>
                          </TabPanel>
                        </Tabs>
                      </TabPanel>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="round_box">
                <div className="cover">
                  <p>No over-pricing</p>
                  <span>Cheapest costs | Competitive prices</span>
                  <span>Pay as you go</span>
                </div>
                <div className="download">
                  <p>Get ₹200 cashback on mobile app download</p>
                  <div className="d-flex justify-content-center">
                    <Link to="https://play.google.com/">
                      <img src={playstore} alt="playstoore" />
                    </Link>
                    <Link to="https://apps.apple.com/">
                      <img src={appstore} alt="Appstoore" />
                    </Link>
                  </div>
                </div>
                <div className="cover">
                  <p>No return fare</p>
                  <span>One-Way cab fare | No hidden charges</span>
                  <span>Minimal advance</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
}

export default Topbar;
