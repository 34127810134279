import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import {Modal,Row,Col} from "react-bootstrap";
import { Link } from "react-router-dom";

function Terms() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Link  onClick={handleShow} className="terms">
      Other Terms
      </Link>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Other Charges and Taxes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="instruction">
            <Row>
              <Col md={12}>
                <p>
                  <strong>- </strong> Vehicle and fuel charges included
                </p>
                <p>
                  <strong>- </strong> Driver Night Charges included
                </p>
                <p>
                  <strong>- </strong> For Round trip bookings, all the local
                  sightseeing in the destination cities is included except for
                  Sikkim and Darjeeling. For local sightseeing in Pickup city
                  (if required), please add all the Pickup city stops in
                  itinerary.
                </p>
                <p>
                  <strong>- </strong> 5% GST Extra
                </p>
                <p>
                  <strong>- </strong> Included Kilometers will start from pickup
                  location
                </p>
                <p>
                  <strong>- </strong> Driver allowance includes driver's stay,
                  food and night charges
                </p>
                <p>
                  <strong>- </strong> Toll and state tax extra
                </p>
                <p>
                  <strong>- </strong> Parking charges extra if applicable
                </p>
                <p>
                  <strong>- </strong> AC will remain switch off in hill areas
                </p>
                <p>
                  <strong>- </strong> For round trip booking, Kilometers will
                  count from pickup location to pickup location
                </p>
                <p>
                  <strong>- </strong> One way trip includes only one pickup and
                  one drop. Additional pickup or drop on the way will incur
                  additional charges of Rs. 250 per pickup / drop.
                </p>
                <p>
                  <strong>- </strong> Rohtang Pass needs special permit and is
                  not included in any trip
                </p>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Terms;
