import React from "react";
import Topbar from "../include/Topbar";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import {
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
  slider01,
  slider02,
  slider03,
  slider04,
  slider_5,
  slider_6,
  slider_7,
  slider_8,
} from "../../assets/images/images";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

function Routes() {
  const scrollTop=()=>{
    window.scrollTo({
     top:0,
     behavior:"smooth",
    });
   }
  return (
    <>
      <Container fluid>
        <Topbar />
        <Row className="tab_slider range">
          <Col sm={12}>
            <Row>
              <Tabs>
                <TabList>
                  <div className="pd_all">
                    <h2 className="heading mb-3">About Destinations</h2>
                    <Tab>Delhi to Manali Cab</Tab>
                    <Tab>About Manali</Tab>
                    <Tab>Route Information</Tab>
                    <Tab>Touriest Attraction</Tab>
                  </div>
                </TabList>

                <TabPanel>
                  <Carousel
                    responsive
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                  >
                    <div>
                      <img src={slider01} alt="Slider" />
                    </div>
                    <div>
                      <img src={slider02} alt="Slider" />
                    </div>
                    <div>
                      <img src={slider03} alt="Slider" />
                    </div>
                  </Carousel>
                </TabPanel>
                <TabPanel>
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                  >
                    <div className="text-start">
                      <div className="about_manali">
                        <h3 className="heading mb-3">About Manali</h3>
                        <div className="top_bar distance d-flex justify-content-between">
                          <p>Delhi to Manali Distance:540km</p>
                          <p>Estimated travel time : 12 hrs</p>
                        </div>
                        <div className="detail">
                          <p>
                            Delhi to Manali is very popular route for tourists
                            as it provides attractions for all types ofusers, be
                            it a family trip away from delhi heat, an adventure
                            sports fan, a nature lover,couples on honeymoon,
                            people having spiritual interests.
                          </p>
                          <p>
                            Enclosed by lofty mountains and dense pinewood
                            forests along with Bias river, Manali is oneof the
                            most visited tourist destination. With snow covered
                            mountains of Rohtang Pass and beautifullandscapes of
                            Solang Valley, Manali gives the mesmerising
                            experience. There are trekking,paragliding, skiing,
                            mountaineering, rock-climbing, zorbing, river
                            rafting for adventure lovers.Hadimba Devi Temple, a
                            14th century wooden temple; Manikaran Sahib
                            Gurudwara and Lord ShivaTemple on banks of Parvati
                            river, having hot water spring adds the spiritual
                            peaceful traitsto Manali.
                          </p>
                          <span>
                            Taxi from Delhi to Manali is readily available at
                            CabRide.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </TabPanel>
                <TabPanel>
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                  >
                    <div className="text-start">
                      <div className="about_manali">
                        <h3 className="heading">Route Information</h3>
                        <div className="top_bar">
                          <p>
                            Delhi to Manali by car is well connected through
                            NH44. Chandigarh is the besthalt point for this
                            journey. There are popular food joints like Sukh Dev
                            Murthal Dhaba,Karnal Haveli, Chokhi Dhani, Savoy
                            Greens,Chawlas, Mandi on the way to relax. Route has
                            260Km of very scenic hilly terrain.Delhi to Manali
                            distance is 540 Km and is 12 hours journey. Other
                            famous tourist places on this route are :
                            Kurukshetra - A holy city, the land of Mahabharta,
                            where Lord Krishna preached Geeta to Arjuna
                          </p>
                        </div>
                        <div className="detail">
                          <span>Panipat: City of Textiles and Carpets</span>
                          <ul>
                            <li>Naina Devi Temple</li>
                            <li>Anandpur Sahib</li>
                            <li>Kiratpur Sahib</li>
                            <li>Revalsar, Mandi</li>
                            <li>Pandoh, Mandi</li>
                            <li>Manikaran, Manali</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </TabPanel>
                <TabPanel>
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    showArrows={false}
                    showIndicators={false}
                  >
                    <div className="text-start">
                      <div className="about_manali">
                        <div className="detail">
                          <h3 className="heading">Tourist Attraction:</h3>
                          <ul>
                            <Row>
                              <Col lg={12} md={6} sm={6} xs={6}>
                                <li>Hadimba temple</li>
                                <li>River Rafting</li>
                                <li>Zorbing</li>
                                <li>Paragliding</li>
                                <li>Gulaba</li>
                                <li>Solang Valley</li>
                                <li>Rohtang Pass</li>
                                <li>Mall Road Manali</li>
                              </Col>
                              <Col lg={12} md={6} sm={6} xs={6}>
                                <li>Manali Gompa</li>
                                <li>Manikaran</li>
                                <li>Jana Waterfall</li>
                                <li>Rahala Waterfall</li>
                                <li>Vashisht Temple</li>
                                <li>Rozy Waterfall</li>
                                <li>Manu Temple</li>
                                <li>Skiing Trekking</li>
                              </Col>
                            </Row>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </TabPanel>
              </Tabs>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="bg-white range border_left">
            <div className="why_cab best">
              <h2 className="heading">Taxi Options</h2>
              <p>
                Being an Aggregator, CabRide provides you enormous car options
                for your Delhi to Manali taxi booking. You can mention your
                specific preference, if any, while raising a taxi booking
                request. Our partnered taxi services providers have various cars
                available with them for Delhi to Manali taxi service. At high
                level, we classify taxi in below four categories :
              </p>
              <h5 className="mt-5">Delhi to Manali Taxi Prices</h5>
              <table class="table fare-table">
                <tbody>
                  <tr>
                    <th>Vehicle Type</th>
                    <th>Model</th>
                    <th>Capacity</th>
                    <th>Per Km Rate</th>
                  </tr>
                  <tr>
                    <td>Hatchback</td>
                    <td>Wagon-R, Indica or similar</td>
                    <td>4 seater</td>
                    <td>Rs. 9</td>
                  </tr>
                  <tr>
                    <td>Sedan</td>
                    <td>Dzire, Etios or similar</td>
                    <td>4 seater</td>
                    <td>Rs. 9</td>
                  </tr>
                  <tr>
                    <td>SUV</td>
                    <td>Marazzo, Ertiga or similar</td>
                    <td>6 seater</td>
                    <td>Rs. 12.5</td>
                  </tr>
                  <tr>
                    <td>Assured Innova</td>
                    <td>Innova, Innova Crysta</td>
                    <td>6 seater</td>
                    <td>Rs. 13.5</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col lg={6} className="light_bg range">
            <div className="why_cab best">
              <h2 className="heading">Taxi Fare</h2>
              <p>
                CabRide is committed to keep the pricing as simple and clear as
                possible. So, we provide one price for your Delhi to Manali taxi
                which covers a specified maximum kilometer and maximum days. So,
                you know upfront how much taxi is going to cost you and plan
                your budget well.
              </p>
              <p className="mt-3">
                The prices are dynamic and transparent and give a win-win deal
                for both you and our partnered taxi services providers. CabRide
                provides the best price for Delhi to Manali cab and our prices
                are generally 20% lower than competition.
              </p>

              <div className="faqs range my-4">
                <h4 className="mb-3">Frequently Asked Questions (FAQs)</h4>
                <Col md={12}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Is local sightseeing included in outstation trip?
                      </Accordion.Header>
                      <Accordion.Body>
                        For Round trip bookings, all the local sightseeing in
                        destination cities is included. Any city other than
                        destination cities are not included. Please make sure to
                        add all destination cities in itinerary. For local
                        sightseeing in Pickup city (if required), please add all
                        the Pickup city stops in itinerary. For One way trip,
                        only one pickup and one drop is included and no
                        sightseeing included.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How to change pickup date, time and return date?
                      </Accordion.Header>
                      <Accordion.Body>
                        Please click on Departure / Return date on top of this
                        page.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Are Driver charges / Driver bata included in the price?
                        Do i need to arrange for Driver food and accomodation
                        during the trip?
                      </Accordion.Header>
                      <Accordion.Body>
                        Yes, all driver charges are included in the price.
                        Driver will take care of his food and accomodation. You
                        need not to arrange that.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        What are extra charges if i need to travel in night
                        hours?
                      </Accordion.Header>
                      <Accordion.Body>
                        There is no extra charges for traveling in night hours.
                        Night charges are included in the price.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Please tell me any extra charge other than the price
                        shown above.
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="mb-0">
                          <li>5% GST is extra.</li>
                          <li>
                            Parking charges, if any, are extra and need to be
                            paid by you as per actuals.
                          </li>
                          <li>
                            Toll tax and State tax may or may not be extra
                            depending on the trip. Please check{" "}
                            <strong>'Other Terms'</strong> mentioned below
                            price.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="tab_slider">
          <Col sm={12}>
            <Row>
              <div className="p-0">
                <Tabs>
                  <TabList>
                    <div className="pd_all">
                      <h2 className="heading">Top Routes</h2>
                      <Tab>Taxi from Bangalore to Ooty</Tab>
                      <Tab>Taxi from Jaipur to Musoorie</Tab>
                      <Tab>Taxi from Delhi to Manali</Tab>
                      <Tab>Taxi from Delhi to Haridwar</Tab>
                    </div>
                  </TabList>

                  <TabPanel>
                    <div className="about">
                      <div className="top_bar">
                        <p>Distance:280km</p>
                        <p>Estimated Time : 6.5 hrs</p>
                      </div>
                      <div className="detail">
                        <span>TOURIST ATTRACTIONS:</span>
                        <ul>
                          <li>Toy Train Ride</li>
                          <li>Botanical Garden</li>
                          <li>Ooty Lake</li>
                          <li>Rose Garden </li>
                          <li>Thread garden</li>
                          <li>Dolphin’s Nose</li>
                          <li>Tea Museum</li>
                          <li>Kalhatti Falls</li>
                          <li>Dodabetta Peak</li>
                          <li>Elk Hill Murugan Temple </li>
                          <li>Avalanche Lake</li>
                          <li>Golf Course</li>
                          <li>Emerald Lake</li>
                          <li>Tiger Hill</li>
                          <li>Pine Forest</li>
                          <li>St. Stephen's Church</li>
                          <li>Pykara Lake and Waterfall</li>
                          <li>Wenlock Downs</li>
                          <li>Kamaraj Sagar Lake</li>
                          <li>Government Museum Ooty</li>
                        </ul>
                        <Link to="/Range">
                          + Explore more about Bangalore to Ooty
                        </Link>

                        <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                          BOOK A CAB
                        </button>
                      </div>
                    </div>
                    <Carousel
                      responsive
                      autoPlay
                      infiniteLoop
                      showThumbs={false}
                      showStatus={false}
                      showArrows={false}
                    >
                      <div>
                        <img src={slider1} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider2} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider3} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider4} alt="Slider" />
                      </div>
                    </Carousel>
                  </TabPanel>
                  <TabPanel>
                    <div className="about">
                      <div className="top_bar">
                        <p>Distance:580km</p>
                        <p>Estimated Time : 9.5 hrs</p>
                      </div>
                      <div className="detail">
                        <span>TOURIST ATTRACTIONS:</span>
                        <ul>
                          <li>Lal Tibba</li>
                          <li>Bhadraj Hill</li>
                          <li>Kempty Falls</li>
                          <li>Gun Hill</li>
                          <li>Shedup Choepelling Temple</li>
                          <li>Happy Valley</li>
                          <li>Library Bazaar</li>
                          <li>Jharipani Falls</li>
                          <li>Mussoorie Jheel</li>
                          <li>Mall Road Mussoorie</li>
                          <li>Nag Tibba</li>
                        </ul>
                        <Link to="/Range">
                          + Explore more about Jaipur to Musoorie
                        </Link>

                        <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                          BOOK A CAB
                        </button>
                      </div>
                    </div>
                    <Carousel
                      responsive
                      autoPlay
                      infiniteLoop
                      showThumbs={false}
                      showStatus={false}
                      showArrows={false}
                    >
                      <div>
                        <img src={slider5} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider6} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider7} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider8} alt="Slider" />
                      </div>
                    </Carousel>
                  </TabPanel>
                  <TabPanel>
                    <div className="about">
                      <div className="top_bar">
                        <p>Distance:540km</p>
                        <p>Estimated Time : 12 hrs</p>
                      </div>
                      <div className="detail">
                        <span>TOURIST ATTRACTIONS:</span>
                        <ul>
                          <li>Hadimba temple</li>
                          <li>River Rafting</li>
                          <li>Zorbing</li>
                          <li>Paragliding</li>
                          <li>Gulaba</li>
                          <li>Solang Valley</li>
                          <li>Rohtang Pass</li>
                          <li>Mall Road Manali</li>
                          <li>Manali Gompa</li>
                          <li>Manikaran</li>
                          <li>Jana Waterfall</li>
                          <li>Rahala Waterfall</li>
                          <li>Vashisht Temple</li>
                          <li>Rozy Waterfall</li>
                          <li>Manu Temple</li>
                          <li>Skiing Trekking</li>
                        </ul>
                        <Link to="/Range">
                          + Explore more about Delhi to Manali
                        </Link>
                        <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                          BOOK A CAB
                        </button>
                      </div>
                    </div>
                    <Carousel
                      responsive
                      autoPlay
                      infiniteLoop
                      showThumbs={false}
                      showStatus={false}
                      showArrows={false}
                    >
                      <div>
                        <img src={slider01} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider02} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider03} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider04} alt="Slider" />
                      </div>
                    </Carousel>
                  </TabPanel>
                  <TabPanel>
                    <div className="about">
                      <div className="top_bar">
                        <p>Distance:210km</p>
                        <p>Estimated Time : 5.5 hrs</p>
                      </div>
                      <div className="detail">
                        <span>TOURIST ATTRACTIONS:</span>
                        <ul>
                          <li>Har ki Pauri</li>
                          <li>Chandi Devi Temple</li>
                          <li>Ganga Aarti</li>
                          <li>Mansa Devi Temple</li>
                          <li>Chilla Wild life Sanctuary</li>
                          <li>Vaishno Devi Temple</li>
                          <li>Maya Devi Temple</li>
                          <li>Bharat Mata Mandir</li>
                          <li>Bada Bazar</li>
                          <li>Daksha Mahadev Temple</li>
                          <li>Sapt Rishi Ashram</li>
                          <li>Ardh Kumbh Mela</li>
                        </ul>
                        <Link to="/Range">
                          + Explore more about Delhi to Haridwar
                        </Link>
                        <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                          BOOK A CAB
                        </button>
                      </div>
                    </div>
                    <Carousel
                      responsive
                      autoPlay
                      infiniteLoop
                      showThumbs={false}
                      showStatus={false}
                      showArrows={false}
                    >
                      <div>
                        <img src={slider_5} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider_6} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider_7} alt="Slider" />
                      </div>
                      <div>
                        <img src={slider_8} alt="Slider" />
                      </div>
                    </Carousel>
                  </TabPanel>
                </Tabs>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Routes;
