import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header, Footer, Home, Prices,Range } from "./components";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Prices" element={<Prices />} />
        <Route path="/Range" element={<Range />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
