import React from 'react'
import {Container, Navbar} from 'react-bootstrap';
import logo from "../../assets/images/Home/logo.png"

function Header() {
  return (
        <>
          <Navbar className="bg-dark" sticky="top">
            <Container>
              <Navbar.Brand href="/">
                <img src={logo}  alt="React Bootstrap logo" className='logo' />
              </Navbar.Brand>
            </Container>
          </Navbar>
        </>
      );
    }

export default Header