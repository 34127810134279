import React, { useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Accordion,
  Badge,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { IoCarSport, IoTime } from "react-icons/io5";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaFacebook, FaTwitter, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { car1, car4, car3, off } from "../../assets/images/images.jsx";
import moment from "moment";
import Swal from 'sweetalert2'
import Terms from "../include/Terms.jsx";

function Prices() {
  const [departureDate, setDepartureDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(moment().add(1, "days"));
  const [validated, setValidated] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMobileNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setMobileNumber(value);
      if (value.length === 10) {
        setErrorMessage('');
      }
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || mobileNumber.length !== 10) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setErrorMessage("The number should be 10 digits long.");
    } else {
      setValidated(true);
      Swal.fire({
        title: "Congratulation!",
        text: "Your cab Booked Successfully!",
        icon: "success",
        timer:2000,
      });
      event.preventDefault();
      handleClose(true);
    }
  };



  return (
    <Container>
      <Row className="mt-md-5 mt-3 price">
        <div id="back_btn" className="col-md-12">
          <h6>
            <Link className="back_btn" to="/">
              <IoMdArrowRoundBack />
              <span>BACK</span>
            </Link>
          </h6>
        </div>

        <Col md={12} className="choose_city">
          <p className="btn btn-primary">Jaipur, Rajasthan, India</p>
          <IoMdArrowRoundForward />
          <p className="btn btn-primary">Delhi, Delhi, India</p>
          <IoMdArrowRoundForward />
          <p className="btn btn-primary">Jaipur, Rajasthan, India</p>
        </Col>

        <Col md={12}>
          <Card className="date_time">
            <div className="text-center bg-dark text-white trip">
              Round Trip
            </div>
            <div className="dates">
              <div className="d-flex align-items-center">
                <h5 className="card-title text-left me-md-3 me-1">Departure</h5>
                <Datetime
                  value={departureDate}
                  onChange={(date) => setDepartureDate(date)}
                />
              </div>
              <div>
                <Badge bg="secondary" className="p-2">
                  1 DAY
                </Badge>
              </div>
              <div className="d-flex align-items-baseline">
                <Datetime
                  value={returnDate}
                  onChange={(date) => setReturnDate(date)}
                />
                <h5 className="card-title text-left ms-md-3 ms-1">Return</h5>
              </div>
            </div>
          </Card>
        </Col>

        <br />

        <Col md={12} className="my-3">
          <Card className="date_time ad">
            <Row className="value">
              <Col lg={4} md={12}>
                <h5 className="card-title text-left">
                  Lowest Prices, Highest Value!!
                </h5>
              </Col>
              <Col lg={8} md={12}>
                <div className="tips">
                  <div className="safety">
                    <span>Safety First</span>
                    <AiFillSafetyCertificate />
                  </div>
                  <div className="safety">
                    <span>Confirmed Cab</span>
                    <IoCarSport />
                  </div>
                  <div className="safety">
                    <span>Always On Time</span>
                    <IoTime />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={12}>
          <Row className="carPrices">
            <Col md={6} lg={4}>
              <Card className="shadow">
                <div className="offer-tag">
                  <img src={off} alt="10%" />
                </div>
                <div className="card-image">
                  <img src={car1} alt="Car" />
                </div>
                <div className="card-body">
                  <h3 className="margin-price">
                    <span className="delete">₹ 6372</span>
                    <br />
                    <span>₹ 5734.6</span>
                  </h3>
                  <div className="text-center">
                    <h6 className="text-info">HATCHBACK (AC)</h6>
                    <h5 className="card-title">Swift, WagonR or Similar</h5>
                  </div>
                  <hr />
                  <table className="col-md-12 details">
                    <tbody>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Included Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">554 Km </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Extra fare/Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">₹ 9.9/Km</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Driver Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Night Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Terms />
                  <hr />
                  <div className="col-md-12 pb-10">
                    <div className="social">
                      <Link to="https://www.facebook.com/" className="facebook">
                        <FaFacebook />
                      </Link>
                      <Link to="https://x.com/?lang=en" className="twitter">
                        <FaTwitter />
                      </Link>
                      <Link to="https://web.whatsapp.com/" className="whatsapp">
                        <FaWhatsapp />
                      </Link>
                      <Link to="mailto:name@rapidtables.com" className="email">
                        <FaEnvelope />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-75"
                      onClick={handleShow}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="shadow">
                <div className="offer-tag">
                  <img src={off} alt="10%" />
                </div>
                <div className="card-image">
                  <img src={car4} alt="Car" />
                </div>
                <div className="card-body">
                  <h3 className="margin-price">
                    <span className="delete">₹ 6372</span>
                    <br />
                    <span> ₹ 5734.6</span>
                  </h3>
                  <div className="text-center">
                    <h6 className="text-info">SEDAN (AC)</h6>
                    <h5 className="card-title">Dzire, Etios or Similar</h5>
                  </div>
                  <hr />
                  <table className="col-md-12 details">
                    <tbody>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Included Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">554 Km </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Extra fare/Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">₹ 9.9/Km</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Driver Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Night Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Terms />
                  <hr />
                  <div className="col-md-12 pb-10">
                    <div className="social">
                      <Link to="https://www.facebook.com/" className="facebook">
                        <FaFacebook />
                      </Link>
                      <Link to="https://x.com/?lang=en" className="twitter">
                        <FaTwitter />
                      </Link>
                      <Link to="https://web.whatsapp.com/" className="whatsapp">
                        <FaWhatsapp />
                      </Link>
                      <Link to="mailto:name@rapidtables.com" className="email">
                        <FaEnvelope />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-75"
                      onClick={handleShow}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="shadow">
                <div className="offer-tag">
                  <img src={off} alt="10%" />
                </div>
                <div className="card-image">
                  <img src={car4} alt="Car" />
                </div>
                <div className="card-body">
                  <h3 className="margin-price">
                    <span className="delete">₹ 8588</span>
                    <br />
                    <span> ₹ 7729</span>
                  </h3>
                  <div className="text-center">
                    <h6 className="text-info">SUV (AC)</h6>
                    <h5 className="card-title">
                      Innova, Ertiga, Marazzo or Similar
                    </h5>
                  </div>
                  <hr />
                  <table className="col-md-12 details">
                    <tbody>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Included Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">554 Km </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Extra fare/Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value"> ₹ 13.5/Km</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Driver Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Night Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Terms />
                  <hr />
                  <div className="col-md-12 pb-10">
                    <div className="social">
                      <Link to="https://www.facebook.com/" className="facebook">
                        <FaFacebook />
                      </Link>
                      <Link to="https://x.com/?lang=en" className="twitter">
                        <FaTwitter />
                      </Link>
                      <Link to="https://web.whatsapp.com/" className="whatsapp">
                        <FaWhatsapp />
                      </Link>
                      <Link to="mailto:name@rapidtables.com" className="email">
                        <FaEnvelope />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-75"
                      onClick={handleShow}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="shadow">
                <div className="offer-tag">
                  <img src={off} alt="10%" />
                </div>
                <div className="card-image">
                  <img src={car1} alt="Car" />
                </div>
                <div className="card-body">
                  <h3 className="margin-price">
                    <span className="delete">₹ 8588</span>
                    <br />
                    <span> ₹ 7729</span>
                  </h3>
                  <div className="text-center">
                    <h6 className="text-info">SUV (AC)</h6>
                    <h5 className="card-title">
                      Innova, Ertiga, Marazzo or Similar
                    </h5>
                  </div>
                  <hr />
                  <table className="col-md-12 details">
                    <tbody>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Included Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">554 Km </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Extra fare/Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value"> ₹ 17.5/Km</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Driver Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Night Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Terms />
                  <hr />
                  <div className="col-md-12 pb-10">
                    <div className="social">
                      <Link to="https://www.facebook.com/" className="facebook">
                        <FaFacebook />
                      </Link>
                      <Link to="https://x.com/?lang=en" className="twitter">
                        <FaTwitter />
                      </Link>
                      <Link to="https://web.whatsapp.com/" className="whatsapp">
                        <FaWhatsapp />
                      </Link>
                      <Link to="mailto:name@rapidtables.com" className="email">
                        <FaEnvelope />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-75"
                      onClick={handleShow}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="shadow">
                <div className="offer-tag">
                  <img src={off} alt="10%" />
                </div>
                <div className="card-image">
                  <img src={car3} alt="Car" />
                </div>
                <div className="card-body">
                  <h3 className="margin-price">
                    <span className="delete">₹ 18250.5</span>
                    <br />
                    <span> ₹ 15150.6</span>
                  </h3>
                  <div className="text-center">
                    <h6 className="text-info">SUV (AC)</h6>
                    <h5 className="card-title">12 Seater Traveller</h5>
                  </div>
                  <hr />
                  <table className="col-md-12 details">
                    <tbody>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Included Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">554 Km </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Extra fare/Km</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value"> ₹ 25.5/Km</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Driver Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          <p className="tag_name">Night Charges</p>
                        </td>
                        <td className="text-end">
                          <span className="tag_value">Included</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Terms />
                  <hr />
                  <Col md={12} className="pb-10">
                    <div className="social">
                      <Link to="https://www.facebook.com/" className="facebook">
                        <FaFacebook />
                      </Link>
                      <Link to="https://x.com/?lang=en" className="twitter">
                        <FaTwitter />
                      </Link>
                      <Link to="https://web.whatsapp.com/" className="whatsapp">
                        <FaWhatsapp />
                      </Link>
                      <Link to="mailto:name@rapidtables.com" className="email">
                        <FaEnvelope />
                      </Link>
                    </div>
                  </Col>
                  <Col md={12} className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-75"
                      onClick={handleShow}
                    >
                      Book Now
                    </button>
                  </Col>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>

        <div className="faqs my-5">
          <h4 className="mb-3 heading">Frequently Asked Questions (FAQs)</h4>
          <Col md={12}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Is local sightseeing included in outstation trip?
                </Accordion.Header>
                <Accordion.Body>
                  For Round trip bookings, all the local sightseeing in
                  destination cities is included. Any city other than
                  destination cities are not included. Please make sure to add
                  all destination cities in itinerary. For local sightseeing in
                  Pickup city (if required), please add all the Pickup city
                  stops in itinerary. For One way trip, only one pickup and one
                  drop is included and no sightseeing included.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How to change pickup date, time and return date?
                </Accordion.Header>
                <Accordion.Body>
                  Please click on Departure / Return date on top of this page.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Are Driver charges / Driver bata included in the price? Do i
                  need to arrange for Driver food and accomodation during the
                  trip?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, all driver charges are included in the price. Driver will
                  take care of his food and accomodation. You need not to
                  arrange that.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What are extra charges if i need to travel in night hours?
                </Accordion.Header>
                <Accordion.Body>
                  There is no extra charges for traveling in night hours. Night
                  charges are included in the price.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Please tell me any extra charge other than the price shown
                  above.
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="mb-0">
                    <li>5% GST is extra.</li>
                    <li>
                      Parking charges, if any, are extra and need to be paid by
                      you as per actuals.
                    </li>
                    <li>
                      Toll tax and State tax may or may not be extra depending
                      on the trip. Please check <strong>'Other Terms'</strong>{" "}
                      mentioned below price.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How much before departure, i have to book the cab?
                </Accordion.Header>
                <Accordion.Body>
                  Although you can book the cab up to 1 hour prior to departure
                  time but we suggest to book 1 day in advance to avoid last
                  minute rush.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Can I book cab by calling customer support?
                </Accordion.Header>
                <Accordion.Body>
                  We are happy to provide you any clarifications required
                  through customer support team but cab booking has to be done
                  either through our website or through our android and iOS
                  mobile app 'CabBazar - Outstation taxi'.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  I want to book cab without paying any advance amount. I will
                  pay on boarding the cab.
                </Accordion.Header>
                <Accordion.Body>
                  Sorry, it is not possible. You need to pay a small 15-20%
                  amount in advance to book the cab on CabRide.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  I need a one way cab for travelling to more than one
                  destination. I will drop at last destination and need not to
                  return to Pickup location.
                </Accordion.Header>
                <Accordion.Body>
                  One way trip includes only one pickup and one drop apart from
                  lunch/tea break during journey. Please place multiple one way
                  bookings i.e. from Pickup to first destination, first
                  destination to second destination and so on.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </div>
      </Row>

      {/* Booking modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="py-2">
          <Modal.Title className="fs-5">
            Fill Contact Details
          </Modal.Title>
        </Modal.Header>
        <div className="Book_cab">
          <div className="header_modal">
          <h5>One way Trip</h5>
          <p>28 May 2024 ,7:00 AM</p>
          </div>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div>
                    <Form.Group>
                      <label className="control-label">Phone</label>
                      <Form.Control
                        className="mobile mb-3"
                        type="text"
                        placeholder="Enter mobile number"
                        maxLength="10"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Mobile Number
                      </Form.Control.Feedback>
                      {errorMessage && (
                        <div className="text-danger">{errorMessage}</div>
                      )}
                    </Form.Group>
                    <Form.Group controlId="name">
                      <label className="control-label">Name</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Enter your name"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Name
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="email">
                      <label className="control-label">Email</label>
                      <input
                        type="email"
                        className="form-control mb-3"
                        placeholder="Enter your email id"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="address">
                      <label className="control-label">Pick up Address</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Enter Pick up Address"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Pick up Address
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <p className="mb-1">Add On Services(Optional)</p>
                      <div>
                      <Form.Check  className="d-inline me-2"/>
                      <label>Pet Allowed for Rs. 520</label>
                      </div>
                      <div>
                      <Form.Check  className="d-inline me-2"/>
                      <label>Assured luggage space for Rs. 500</label>
                      </div>
                    </Form.Group>
                    <Modal.Footer className="justify-content-center border-0">
                      <Button className="w-100 rounded-pill py-2" type="submit">Pay ₹ 2500 and Book</Button>
                    </Modal.Footer>
                  </div>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </Container>
  );
}

export default Prices;
