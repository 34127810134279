import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row,Col } from "react-bootstrap";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import {playstore,appstore} from "../../assets/images/images";
import ScrollToTop from "react-scroll-to-top";

function Footer() {
  const [showcity, setShowcity] = useState(false);
  const shownewCity = () => {
    setShowcity(!showcity);
  };
  const [showroute, setShowroute] = useState(false);
  const shownewRoutes = () => {
    setShowroute(true);
  };

  return (
    <>
    <ScrollToTop smooth  top={'800'} width="20" height="20" color="red"/>
      <div className="footer">
        <Container fluid>
          <div className="cities py-5">
            <Row className="text-white text-justify text-center-mb">
              <Col sm={12}
                id="cities"
                className="show-cities"
              >
                <span className="fw-bold">
                  Popular Cities:-
                </span>
                <a href="/Range" className="text-white">
                  Agra
                </a>
                |
                <a href="/Range" className="text-white">
                  Ahmedabad
                </a>
                |
                <a href="/Range" className="text-white">
                  Ahmednagar
                </a>
                |
                <a href="/Range" className="text-white">
                  Ajmer
                </a>
                |
                <a href="/Range" className="text-white">
                  Aligarh
                </a>
                |
                <a href="/Range" className="text-white">
                  Allahabad
                </a>
                |
                <a href="/Range" className="text-white">
                  Almora
                </a>
                |
                <a href="/Range" className="text-white">
                  Alwar
                </a>
                |
                <a href="/Range" className="text-white">
                  Ambala
                </a>
                |
                <a href="/Range" className="text-white">
                  Ambernath
                </a>
                |
                <a href="/Range" className="text-white">
                  Amravati
                </a>
                |
                <a href="/Range" className="text-white">
                  Amritsar
                </a>
                |
                <a href="/Range" className="text-white">
                  Anand
                </a>
                |
                  <a href="/Range" className="text-white">
                    Anantapur
                  </a>
                  |
                {showcity &&(<div className="d-inline">
                  <a href="/Range" className="text-white">
                    Anjuna
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Ankleshwar
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Asansol
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Aurangabad
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Baddi
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Badlapur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bahadurgarh
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bangalore
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bareilly
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bathinda
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Belgaum
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Berhampur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhagalpur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bharatpur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bharuch
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhavnagar
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhilai
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhilwara
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhiwadi
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhiwandi
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhopal
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhubaneswar
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bhuj
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bijnor
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bikaner
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bilaspur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bokaro
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Bulandshahr
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Burdwan
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Calangute
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Chandigarh
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Chengannur
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Chennai
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Coimbatore
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Coorg
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Cuttack
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Darbhanga
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Darjeeling
                  </a>
                  |
                  <a href="/Range" className="text-white">
                    Davangere
                  </a>
                  |
                  <a href="#city/dehradun" className="text-white">
                    Dehradun
                  </a>
                  |
                  <a href="#city/delhi" className="text-white">
                    Delhi
                  </a>
                  |
                  <a href="#city/deoghar" className="text-white">
                    Deoghar
                  </a>
                  |
                  <a href="#city/dhanbad" className="text-white">
                    Dhanbad
                  </a>
                  |
                  <a href="#city/dharamshala" className="text-white">
                    Dharamshala
                  </a>
                  |
                  <a href="#city/dhule" className="text-white">
                    Dhule
                  </a>
                  |
                  <a href="#city/dindigul" className="text-white">
                    Dindigul
                  </a>
                  |
                  <a href="#city/dombivli" className="text-white">
                    Dombivli
                  </a>
                  |
                  <a href="#city/durgapur" className="text-white">
                    Durgapur
                  </a>
                  |
                  <a href="#city/dwarka" className="text-white">
                    Dwarka
                  </a>
                  |
                  <a href="#city/eluru" className="text-white">
                    Eluru
                  </a>
                  |
                  <a href="#city/erode" className="text-white">
                    Erode
                  </a>
                  |
                  <a href="#city/faizabad" className="text-white">
                    Faizabad
                  </a>
                  |
                  <a href="#city/faridabad" className="text-white">
                    Faridabad
                  </a>
                  |
                  <a href="#city/firozabad" className="text-white">
                    Firozabad
                  </a>
                  |
                  <a href="#city/gandhidham" className="text-white">
                    Gandhidham
                  </a>
                  |
                  <a href="#city/gandhinagar" className="text-white">
                    Gandhinagar
                  </a>
                  |
                  <a href="#city/gangtok" className="text-white">
                    Gangtok
                  </a>
                  |
                  <a href="#city/ghaziabad" className="text-white">
                    Ghaziabad
                  </a>
                  |
                  <a href="#city/goa" className="text-white">
                    Goa
                  </a>
                  |
                  <a href="#city/gorakhpur" className="text-white">
                    Gorakhpur
                  </a>
                  |
                  <a href="#city/greater-noida" className="text-white">
                    Greater Noida
                  </a>
                  |
                  <a href="#city/guntur" className="text-white">
                    Guntur
                  </a>
                  |
                  <a href="#city/gurgaon" className="text-white">
                    Gurgaon
                  </a>
                  |
                  <a href="#city/guwahati" className="text-white">
                    Guwahati
                  </a>
                  |
                  <a href="#city/gwalior" className="text-white">
                    Gwalior
                  </a>
                  |
                  <a href="#city/hanamkonda" className="text-white">
                    Hanamkonda
                  </a>
                  |
                  <a href="#city/hapur" className="text-white">
                    Hapur
                  </a>
                  |
                  <a href="#city/haridwar" className="text-white">
                    Haridwar
                  </a>
                  |
                  <a href="#city/hisar" className="text-white">
                    Hisar
                  </a>
                  |
                  <a href="#city/hosur" className="text-white">
                    Hosur
                  </a>
                  |
                  <a href="#city/howrah" className="text-white">
                    Howrah
                  </a>
                  |
                  <a href="#city/hubli" className="text-white">
                    Hubli
                  </a>
                  |
                  <a href="#city/hyderabad" className="text-white">
                    Hyderabad
                  </a>
                  |
                  <a href="#city/imphal" className="text-white">
                    Imphal
                  </a>
                  |
                  <a href="#city/indore" className="text-white">
                    Indore
                  </a>
                  |
                  <a href="#city/jabalpur" className="text-white">
                    Jabalpur
                  </a>
                  |
                  <a href="#city/jagdalpur" className="text-white">
                    Jagdalpur
                  </a>
                  |
                  <a href="#city/jaipur" className="text-white">
                    Jaipur
                  </a>
                  |
                  <a href="#city/jalandhar" className="text-white">
                    Jalandhar
                  </a>
                  |
                  <a href="#city/jalgaon" className="text-white">
                    Jalgaon
                  </a>
                  |
                  <a href="#city/jammu" className="text-white">
                    Jammu
                  </a>
                  |
                  <a href="#city/jamnagar" className="text-white">
                    Jamnagar
                  </a>
                  |
                  <a href="#city/jamshedpur" className="text-white">
                    Jamshedpur
                  </a>
                  |
                  <a href="#city/jhansi" className="text-white">
                    Jhansi
                  </a>
                  |
                  <a href="#city/jind" className="text-white">
                    Jind
                  </a>
                  |
                  <a href="#city/jodhpur" className="text-white">
                    Jodhpur
                  </a>
                  |
                  <a href="#city/jorhat" className="text-white">
                    Jorhat
                  </a>
                  |
                  <a href="#city/junagadh" className="text-white">
                    Junagadh
                  </a>
                  |
                  <a href="#city/kadapa" className="text-white">
                    Kadapa
                  </a>
                  |
                  <a href="#city/kakinada" className="text-white">
                    Kakinada
                  </a>
                  |
                  <a href="#city/kalyan" className="text-white">
                    Kalyan
                  </a>
                  |
                  <a href="#city/kanpur" className="text-white">
                    Kanpur
                  </a>
                  |
                  <a href="#city/kanyakumari" className="text-white">
                    Kanyakumari
                  </a>
                  |
                  <a href="#city/karnal" className="text-white">
                    Karnal
                  </a>
                  |
                  <a href="#city/katra" className="text-white">
                    Katra
                  </a>
                  |
                  <a href="#city/khajuraho" className="text-white">
                    Khajuraho
                  </a>
                  |
                  <a href="#city/khammam" className="text-white">
                    Khammam
                  </a>
                  |
                  <a href="#city/kharagpur" className="text-white">
                    Kharagpur
                  </a>
                  |
                  <a href="#city/kharar" className="text-white">
                    Kharar
                  </a>
                  |
                  <a href="#city/kochi" className="text-white">
                    Kochi
                  </a>
                  |
                  <a href="#city/kohima" className="text-white">
                    Kohima
                  </a>
                  |
                  <a href="#city/kolhapur" className="text-white">
                    Kolhapur
                  </a>
                  |
                  <a href="#city/kolkata" className="text-white">
                    Kolkata
                  </a>
                  |
                  <a href="#city/kollam" className="text-white">
                    Kollam
                  </a>
                  |
                  <a href="#city/korba" className="text-white">
                    Korba
                  </a>
                  |
                  <a href="#city/kota" className="text-white">
                    Kota
                  </a>
                  |
                  <a href="#city/kozhikode" className="text-white">
                    Kozhikode
                  </a>
                  |
                  <a href="#city/kurnool" className="text-white">
                    Kurnool
                  </a>
                  |
                  <a href="#city/kurukshetra" className="text-white">
                    Kurukshetra
                  </a>
                  |
                  <a href="#city/lakhimpur" className="text-white">
                    Lakhimpur
                  </a>
                  |
                  <a href="#city/lonavala" className="text-white">
                    Lonavala
                  </a>
                  |
                  <a href="#city/lucknow" className="text-white">
                    Lucknow
                  </a>
                  |
                  <a href="#city/ludhiana" className="text-white">
                    Ludhiana
                  </a>
                  |
                  <a href="#city/madgaon" className="text-white">
                    Madgaon
                  </a>
                  |
                  <a href="#city/madurai" className="text-white">
                    Madurai
                  </a>
                  |
                  <a href="#city/malda" className="text-white">
                    Malda
                  </a>
                  |
                  <a href="#city/manali" className="text-white">
                    Manali
                  </a>
                  |
                  <a href="#city/mangalore" className="text-white">
                    Mangalore
                  </a>
                  |
                  <a href="#city/manmad" className="text-white">
                    Manmad
                  </a>
                  |
                  <a href="#city/mapusa" className="text-white">
                    Mapusa
                  </a>
                  |
                  <a href="#city/mathura" className="text-white">
                    Mathura
                  </a>
                  |
                  <a href="#city/mcleodganj" className="text-white">
                    Mcleodganj
                  </a>
                  |
                  <a href="#city/meerut" className="text-white">
                    Meerut
                  </a>
                  |
                  <a href="#city/mehsana" className="text-white">
                    Mehsana
                  </a>
                  |
                  <a href="#city/mettupalayam" className="text-white">
                    Mettupalayam
                  </a>
                  |
                  <a href="#city/mohali" className="text-white">
                    Mohali
                  </a>
                  |
                  <a href="#city/moradabad" className="text-white">
                    Moradabad
                  </a>
                  |
                  <a href="#city/morbi" className="text-white">
                    Morbi
                  </a>
                  |
                  <a href="#city/mumbai" className="text-white">
                    Mumbai
                  </a>
                  |
                  <a href="#city/munnar" className="text-white">
                    Munnar
                  </a>
                  |
                  <a href="#city/mussoorie" className="text-white">
                    Mussoorie
                  </a>
                  |
                  <a href="#city/muzaffarnagar" className="text-white">
                    Muzaffarnagar
                  </a>
                  |
                  <a href="#city/muzaffarpur" className="text-white">
                    Muzaffarpur
                  </a>
                  |
                  <a href="#city/mysore" className="text-white">
                    Mysore
                  </a>
                  |
                  <a href="#city/nadiad" className="text-white">
                    Nadiad
                  </a>
                  |
                  <a href="#city/nagercoil" className="text-white">
                    Nagercoil
                  </a>
                  |
                  <a href="#city/nagpur" className="text-white">
                    Nagpur
                  </a>
                  |
                  <a href="#city/nanded" className="text-white">
                    Nanded
                  </a>
                  |
                  <a href="#city/nashik" className="text-white">
                    Nashik
                  </a>
                  |
                  <a href="#city/navsari" className="text-white">
                    Navsari
                  </a>
                  |
                  <a href="#city/nellore" className="text-white">
                    Nellore
                  </a>
                  |
                  <a href="#city/nizamabad" className="text-white">
                    Nizamabad
                  </a>
                  |
                  <a href="#city/noida" className="text-white">
                    Noida
                  </a>
                  |
                  <a href="#city/ongole" className="text-white">
                    Ongole
                  </a>
                  |
                  <a href="#city/ooty" className="text-white">
                    Ooty
                  </a>
                  |
                  <a href="#city/palakkad" className="text-white">
                    Palakkad
                  </a>
                  |
                  <a href="#city/palani" className="text-white">
                    Palani
                  </a>
                  |
                  <a href="#city/palanpur" className="text-white">
                    Palanpur
                  </a>
                  |
                  <a href="#city/panchkula" className="text-white">
                    Panchkula
                  </a>
                  |
                  <a href="#city/panipat" className="text-white">
                    Panipat
                  </a>
                  |
                  <a href="#city/panjim" className="text-white">
                    Panjim
                  </a>
                  |
                  <a href="#city/panvel" className="text-white">
                    Panvel
                  </a>
                  |
                  <a href="#city/pathankot" className="text-white">
                    Pathankot
                  </a>
                  |
                  <a href="#city/patiala" className="text-white">
                    Patiala
                  </a>
                  |
                  <a href="#city/patna" className="text-white">
                    Patna
                  </a>
                  |
                  <a href="#city/pimpri_chinchwad" className="text-white">
                    Pimpri Chinchwad
                  </a>
                  |
                  <a href="#city/pollachi" className="text-white">
                    Pollachi
                  </a>
                  |
                  <a href="#city/pondicherry" className="text-white">
                    Pondicherry
                  </a>
                  |
                  <a href="#city/pune" className="text-white">
                    Pune
                  </a>
                  |
                  <a href="#city/puri" className="text-white">
                    Puri
                  </a>
                  |
                  <a href="#city/pushkar" className="text-white">
                    Pushkar
                  </a>
                  |
                  <a href="#city/raipur" className="text-white">
                    Raipur
                  </a>
                  |
                  <a href="#city/rajahmundry" className="text-white">
                    Rajahmundry
                  </a>
                  |
                  <a href="#city/rajkot" className="text-white">
                    Rajkot
                  </a>
                  |
                  <a href="#city/rameshwaram" className="text-white">
                    Rameshwaram
                  </a>
                  |
                  <a href="#city/rampur" className="text-white">
                    Rampur
                  </a>
                  |
                  <a href="#city/ranchi" className="text-white">
                    Ranchi
                  </a>
                  |
                  <a href="#city/ratnagiri" className="text-white">
                    Ratnagiri
                  </a>
                  |
                  <a href="#city/rewa" className="text-white">
                    Rewa
                  </a>
                  |
                  <a href="#city/rewari" className="text-white">
                    Rewari
                  </a>
                  |
                  <a href="#city/rishikesh" className="text-white">
                    Rishikesh
                  </a>
                  |
                  <a href="#city/rohtak" className="text-white">
                    Rohtak
                  </a>
                  |
                  <a href="#city/rourkela" className="text-white">
                    Rourkela
                  </a>
                  |
                  <a href="#city/rudrapur" className="text-white">
                    Rudrapur
                  </a>
                  |
                  <a href="#city/sagar" className="text-white">
                    Sagar
                  </a>
                  |
                  <a href="#city/saharanpur" className="text-white">
                    Saharanpur
                  </a>
                  |
                  <a href="#city/salem" className="text-white">
                    Salem
                  </a>
                  |
                  <a href="#city/sangli" className="text-white">
                    Sangli
                  </a>
                  |
                  <a href="#city/satna" className="text-white">
                    Satna
                  </a>
                  |
                  <a href="#city/secunderabad" className="text-white">
                    Secunderabad
                  </a>
                  |
                  <a href="#city/shillong" className="text-white">
                    Shillong
                  </a>
                  |
                  <a href="#city/shimla" className="text-white">
                    Shimla
                  </a>
                  |
                  <a href="#city/shimoga" className="text-white">
                    Shimoga
                  </a>
                  |
                  <a href="#city/shirdi" className="text-white">
                    Shirdi
                  </a>
                  |
                  <a href="#city/sikar" className="text-white">
                    Sikar
                  </a>
                  |
                  <a href="#city/siliguri" className="text-white">
                    Siliguri
                  </a>
                  |
                  <a href="#city/sirsa" className="text-white">
                    Sirsa
                  </a>
                  |
                  <a href="#city/solan" className="text-white">
                    Solan
                  </a>
                  |
                  <a href="#city/solapur" className="text-white">
                    Solapur
                  </a>
                  |
                  <a href="#city/somnath" className="text-white">
                    Somnath
                  </a>
                  |
                  <a href="#city/sonipat" className="text-white">
                    Sonipat
                  </a>
                  |
                  <a href="#city/sri-ganganagar" className="text-white">
                    Sri Ganganagar
                  </a>
                  |
                  <a href="#city/surat" className="text-white">
                    Surat
                  </a>
                  |
                  <a href="#city/thane" className="text-white">
                    Thane
                  </a>
                  |
                  <a href="#city/thrissur" className="text-white">
                    Thrissur
                  </a>
                  |
                  <a href="#city/tirunelveli" className="text-white">
                    Tirunelveli
                  </a>
                  |
                  <a href="#city/tirupati" className="text-white">
                    Tirupati
                  </a>
                  |
                  <a href="#city/trichy" className="text-white">
                    Trichy
                  </a>
                  |
                  <a href="#city/trivandrum" className="text-white">
                    Trivandrum
                  </a>
                  |
                  <a href="#city/udaipur" className="text-white">
                    Udaipur
                  </a>
                  |
                  <a href="#city/udupi" className="text-white">
                    Udupi
                  </a>
                  |
                  <a href="#city/ujjain" className="text-white">
                    Ujjain
                  </a>
                  |
                  <a href="#city/ulhasnagar" className="text-white">
                    Ulhasnagar
                  </a>
                  |
                  <a href="#city/vadodara" className="text-white">
                    Vadodara
                  </a>
                  |
                  <a href="#city/valsad" className="text-white">
                    Valsad
                  </a>
                  |
                  <a href="#city/vapi" className="text-white">
                    Vapi
                  </a>
                  |
                  <a href="#city/varanasi" className="text-white">
                    Varanasi
                  </a>
                  |
                  <a href="#city/varkala" className="text-white">
                    Varkala
                  </a>
                  |
                  <a href="#city/vasai" className="text-white">
                    Vasai
                  </a>
                  |
                  <a href="#city/vellore" className="text-white">
                    Vellore
                  </a>
                  |
                  <a href="#city/vijayawada" className="text-white">
                    Vijayawada
                  </a>
                  |
                  <a href="#city/villupuram" className="text-white">
                    Villupuram
                  </a>
                  |
                  <a href="#city/virar" className="text-white">
                    Virar
                  </a>
                  |
                  <a href="#city/visakhapatnam" className="text-white">
                    Visakhapatnam
                  </a>
                  |
                  <a href="#city/vizianagaram" className="text-white">
                    Vizianagaram
                  </a>
                  |
                  <a href="#city/vrindavan" className="text-white">
                    Vrindavan
                  </a>
                  |
                  <a href="#city/warangal" className="text-white">
                    Warangal
                  </a>
                  |
                  <a href="#city/wardha" className="text-white">
                    Wardha
                  </a>
                  |
                  <a href="#city/wayanad" className="text-white">
                    Wayanad
                  </a>
                  |
                  <a href="#city/zirakpur" className="text-white">
                    Zirakpur
                  </a>
                </div>)}
              </Col>
              {!showcity && (
                 <Col sm={12}
                 className="text-center"
                 onClick={shownewCity}
               >
                 + Explore more cities
               </Col>
                 
              )}
             
              <Col sm={12}>
                <hr />
              </Col>
              <Col sm={12} className="text-center">
                <a href="#city/one-way-cab" className="text-white">
                  One way Cab
                </a>
                |
                <a href="#city/car-rental" className="text-white">
                  Car Rental
                </a>
                |
                <a href="#taxi/airport" className="text-white">
                  Airport Taxi
                </a>
                |
                <a href="#city/local-sightseeing" className="text-white">
                  Local Sightseeing
                </a>
                |
                <a href="#city/innova-crysta-taxi" className="text-white">
                  Innova
                </a>
                |
                <a href="#tempo-traveller-on-rent" className="text-white">
                  Tempo Traveller
                </a>
                |
                <a href="#taxi/pet-friendly-cab" className="text-white">
                  Pet Friendly Cab
                </a>
                |
                <a href="#bus-ticket-booking" className="text-white">
                  Bus
                </a>
              </Col>
              <Col sm={12}>
                <hr />
              </Col>
            </Row>
          </div>
          <Row id="routes" className="text-white text-sm-start text-center">
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Delhi</h6>
              <ul>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Delhi to Agra
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Delhi to Jaipur
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Delhi to Chandigarh
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Delhi to Amritsar
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Delhi to Manali
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Bangalore</h6>
              <ul>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Bangalore to Mysore
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Bangalore to Coorg
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Bangalore to Ooty
                  </a>
                </li>
                <li>
                  <a
                    href="/Range"
                    className="text-white"
                  >
                    Taxi from Bangalore to Pondicherry
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Bangalore to Goa
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Mumbai</h6>
              <ul>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Mumbai to Shirdi
                  </a>
                </li>
                <li>
                  <a
                    href="/Range"
                    className="text-white"
                  >
                    Taxi from Mumbai to Shani Shingnapur
                  </a>
                </li>
                <li>
                  <a
                    href="/Range"
                    className="text-white"
                  >
                    Taxi from Mumbai to Mahabaleshwar
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Mumbai to Nashik
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Mumbai to Pune
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Chennai</h6>
              <ul>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Chennai to Bangalore
                  </a>
                </li>
                <li>
                  <a
                    href="/Range"
                    className="text-white"
                  >
                    Taxi from Chennai to Pondicherry
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Chennai to Tirupati
                  </a>
                </li>
                <li>
                  <a
                    href="/Range"
                    className="text-white"
                  >
                    Taxi from Chennai to Mahabalipuram
                  </a>
                </li>
                <li>
                  <a href="/Range" className="text-white">
                    Taxi from Chennai to Kanchipuram
                  </a>
                </li>
              </ul>
            </Col>
            {showroute &&(
              <>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Chandigarh</h6>
              <ul>
                <li>
                  <a href="#route/chandigarh-to-delhi" className="text-white">
                    Taxi from Chandigarh to Delhi
                  </a>
                </li>
                <li>
                  <a
                    href="#route/chandigarh-to-amritsar"
                    className="text-white"
                  >
                    Taxi from Chandigarh to Amritsar
                  </a>
                </li>
                <li>
                  <a href="#route/chandigarh-to-manali" className="text-white">
                    Taxi from Chandigarh to Manali
                  </a>
                </li>
                <li>
                  <a href="#route/chandigarh-to-shimla" className="text-white">
                    Taxi from Chandigarh to Shimla
                  </a>
                </li>
                <li>
                  <a
                    href="#route/chandigarh-to-ludhiana"
                    className="text-white"
                  >
                    Taxi from Chandigarh to Ludhiana
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Jaipur</h6>
              <ul>
                <li>
                  <a href="#route/jaipur-to-agra" className="text-white">
                    Taxi from Jaipur to Agra
                  </a>
                </li>
                <li>
                  <a href="#route/jaipur-to-delhi" className="text-white">
                    Taxi from Jaipur to Delhi
                  </a>
                </li>
                <li>
                  <a href="#route/jaipur-to-udaipur" className="text-white">
                    Taxi from Jaipur to Udaipur
                  </a>
                </li>
                <li>
                  <a href="#route/jaipur-to-jodhpur" className="text-white">
                    Taxi from Jaipur to Jodhpur
                  </a>
                </li>
                <li>
                  <a href="#route/jaipur-to-ajmer" className="text-white">
                    Taxi from Jaipur to Ajmer
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Lucknow</h6>
              <ul>
                <li>
                  <a href="#route/lucknow-to-delhi" className="text-white">
                    Taxi from Lucknow to Delhi
                  </a>
                </li>
                <li>
                  <a href="#route/lucknow-to-kanpur" className="text-white">
                    Taxi from Lucknow to Kanpur
                  </a>
                </li>
                <li>
                  <a href="#route/lucknow-to-bareilly" className="text-white">
                    Taxi from Lucknow to Bareilly
                  </a>
                </li>
                <li>
                  <a href="#route/lucknow-to-agra" className="text-white">
                    Taxi from Lucknow to Agra
                  </a>
                </li>
                <li>
                  <a href="#route/lucknow-to-varanasi" className="text-white">
                    Taxi from Lucknow to Varanasi
                  </a>
                </li>
              </ul>
            </Col>
            <Col xl={3} lg={4} md={6} sm={6}>
              <h6>Cab from Ludhiana</h6>
              <ul>
                <li>
                  <a href="#route/ludhiana-to-delhi" className="text-white">
                    Taxi from Ludhiana to Delhi
                  </a>
                </li>
                <li>
                  <a
                    href="#route/ludhiana-to-chandigarh"
                    className="text-white"
                  >
                    Taxi from Ludhiana to Chandigarh
                  </a>
                </li>
                <li>
                  <a href="#route/ludhiana-to-amritsar" className="text-white">
                    Taxi from Ludhiana to Amritsar
                  </a>
                </li>
                <li>
                  <a href="#route/ludhiana-to-manali" className="text-white">
                    Taxi from Ludhiana to Manali
                  </a>
                </li>
                <li>
                  <a href="#route/ludhiana-to-agra" className="text-white">
                    Taxi from Ludhiana to Agra
                  </a>
                </li>
              </ul>
            </Col>
            </>
            )}
            {!showroute && (
                 <Col sm={12}
                 className="text-center"
                 onClick={shownewRoutes}
               >
                 + Explore more Routes
               </Col>
                 
              )}
          </Row>
          <hr className="border-bottom border" />

          <Row className="align-items-center social_dwld">
            <Col lg={6} md={6} sm={12} className="app">
              <Link
                to="https://play.google.com/store/apps"
                target="_blank"
                className="d-inline"
              >
                <img
                  src={playstore}
                  alt="Get it on Google Play"
                  className="img-fluid"
                />
              </Link>
              <Link
                to="https://itunes.apple.com/us/app/"
                target="_blank"
                className="d-inline"
              >
                <img
                  src={appstore}
                  alt="Download on App Store"
                  className="img-fluid"
                />
              </Link>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="social">
                <Link to="https://www.facebook.com/">
                  <FaFacebook />
                </Link>
                <Link to="https://x.com/?lang=en">
                  <FaTwitter />
                </Link>
                <Link to="https://in.linkedin.com/">
                  <FaLinkedinIn />
                </Link>
                <Link to="https://www.instagram.com/">
                  <FaInstagram />
                </Link>
                <Link to="https://www.youtube.com/">
                  <FaYoutube />
                </Link>
              </div>
            </Col>
          </Row>
          <hr className="border-bottom border" />

          <Row>
            <Col lg={12} className="terms">
              <a href="#/terms-of-use" className="text-white">
                Terms of Use
              </a>
              |
              <a href="route/privacy-policy" className="text-white">
                Privacy Policy
              </a>
              |
              <a href="#/refund-policy" className="text-white">
                Refund Policy
              </a>
              |
              <a href="#/service-level-agreement" className="text-white">
                Service Level Agreement for Vendor / Driver
              </a>
              |
              <a
                href="#jobs/careers"
                className="text-white"
              >
                Career
              </a>
              |
              <a
                href="#taxi/cab-booking-app"
                className="text-white"
              >
                Mobile App
              </a>
              |
              <a
                href="#media-coverage"
                className="text-white"
              >
                Media Coverage
              </a>
              |
              <a href="#partner" className="text-white">
                Affiliate / Sales Partner
              </a>
              |
              <a href="/#contact-us" className="text-white">
                Contact Us
              </a>
            </Col>
          </Row>
          <hr className="border-bottom border" />
          <Row>
            <Col lg={12} sm={12} className="text-center">
              <span className="text_copy">
                Copyright © 2024 A2technosoft Private Limited. All rights
                reserved.
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
