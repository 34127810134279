import React, { useState } from "react";
import {Link } from "react-router-dom";
import { Card, Col, Container, Row, Button, Form } from "react-bootstrap";
import {playstore, appstore, car, car2, slider1, slider2, slider3, slider4, slider5, slider6, slider7, slider8, slider01, slider02, slider03, slider04, slider_5, slider_6, slider_7, slider_8, Mob, test1, test2, test3, media1, media2, media3,media4} from "../../assets/images/images";
import { FaFacebook,  FaTwitter,  FaLinkedinIn,  FaInstagram,  FaYoutube,} from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import Topbar from "../include/Topbar";
import Swal from 'sweetalert2'
import "react-tabs/style/react-tabs.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  const [validated, setValidated] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess]= useState(false);
  
  const handleMobileNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setMobileNumber(value);
      if (value.length === 10) {
        setErrorMessage('');
      }
    }
  };

  const scrollTop=()=>{
   window.scrollTo({
    top:0,
    behavior:"smooth",
   });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || mobileNumber.length !== 10) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setErrorMessage("The number should be 10 digits long.");
    }
    
  else {
      setValidated(true);
      setShowSuccess(true);
      Swal.fire({
        title: "Thanks!",
        text: "We will get back to you soon.",
        icon: "success",
        timer:2000,
      });
      event.preventDefault();
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    touchMove: false,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive:[
      {
      breakpoint:991,
      settings:{
        slidesToShow:2,
        slidesToScroll:2,
      },
    },
      {
      breakpoint:767,
      settings:{
        slidesToShow:1,
        slidesToScroll:1,
      },
    },
    ],
  };

  return (
    <>
    <Container fluid>
     <Topbar/>
      <Row>
        <Col lg={6} className="light_bg mob-2">
          <div className="why_img">
            <img src={car} alt="car1" className="img-rounded" />
          </div>
        </Col>
        <Col lg={6} className="bg-white border_right">
          <div className="why_cab">
            <h2 className="heading">Why CabRide ?</h2>
            <p>Clean Car</p>
            <ul>
              <li>Sanitised Post-Ride</li> <li>Professionally</li>{" "}
              <li>Cleaned Odour-Free</li>
            </ul>
            <p>Transparent Billing</p>
            <ul>
              <li>Simple pricing</li>
              <li>No Night Charges</li> <li>No Driver Charges</li>
            </ul>
            <p>Reliable Service</p>
            <ul>
              <li>Immediate Driver details</li> <li>On Time</li>{" "}
              <li>Thousands of Cabs</li>
            </ul>
            <p>Professional Drivers</p>
            <ul>
              <li>Gentle,Well-Behaved</li> <li> Verified Trained</li>{" "}
              <li>Customer-Centric</li>
            </ul>
            <p>Services</p>
            <ul>
              <li>Outstation cab booking</li> <li>intercity cabs</li>{" "}
              <li>Local car rental</li> <li>Airport transfers</li>
            </ul>
            <button className="btn btn-primary btn-book-cab" onClick={scrollTop}>BOOK CAB</button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="bg-white border_left">
          <div className="why_cab best">
            <h2 className="heading">Best outstation taxi service in India</h2>
            <p>No over-pricing</p>
            <ul>
              <li>Cheapest costs</li> <li>Competitive prices</li>{" "}
              <li>Pay as you go</li>
            </ul>
            <p>Cabs availability</p>
            <ul>
              <li>No last minute surprises</li>
              <li>Large pool of cabs</li>{" "}
              <li>All seasons, All routes cab for outstation</li>
            </ul>
            <p>No return fare</p>
            <ul>
              <li>One-Way cab fare</li> <li> No hidden charges</li>{" "}
              <li>Minimal advance</li>
            </ul>

            <button className="btn btn-primary btn-book-cab" onClick={scrollTop}>
              BOOK OUTSTATION CAB
            </button>
          </div>
        </Col>
        <Col lg={6} className="light_bg">
          <div className="why_img why2">
            <img src={car2} alt="car1" className="img-rounded" />
          </div>
        </Col>
      </Row>

      <Row className="tab_slider">
        <Col sm={12}>
        <Row>
          <div className="p-0">
            <Tabs>
              <TabList>
                <div className="pd_all">
                  <h2 className="heading">Popular Destinations</h2>
                  <Tab>Taxi from Bangalore to Ooty</Tab>
                  <Tab>Taxi from Jaipur to Musoorie</Tab>
                  <Tab>Taxi from Delhi to Manali</Tab>
                  <Tab>Taxi from Delhi to Haridwar</Tab>
                </div>
              </TabList>

              <TabPanel>
                <div className="about">
                  <div className="top_bar">
                    <p>Distance:280km</p>
                    <p>Estimated Time : 6.5 hrs</p>
                  </div>
                  <div className="detail">
                    <span>TOURIST ATTRACTIONS:</span>
                    <ul>
                      <li>Toy Train Ride</li>
                      <li>Botanical Garden</li>
                      <li>Ooty Lake</li>
                      <li>Rose Garden </li>
                      <li>Thread garden</li>
                      <li>Dolphin’s Nose</li>
                      <li>Tea Museum</li>
                      <li>Kalhatti Falls</li>
                      <li>Dodabetta Peak</li>
                      <li>Elk Hill Murugan Temple </li>
                      <li>Avalanche Lake</li>
                      <li>Golf Course</li>
                      <li>Emerald Lake</li>
                      <li>Tiger Hill</li>
                      <li>Pine Forest</li>
                      <li>St. Stephen's Church</li>
                      <li>Pykara Lake and Waterfall</li>
                      <li>Wenlock Downs</li>
                      <li>Kamaraj Sagar Lake</li>
                      <li>Government Museum Ooty</li>
                    </ul>
                    <Link to='/Range'>+ Explore more about Bangalore to Ooty</Link>

                    <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                      BOOK A CAB
                    </button>
                  </div>
                </div>
                <Carousel
                  responsive
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                >
                  <div>
                    <img src={slider1} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider2} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider3} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider4} alt="Slider" />
                  </div>
                </Carousel>
              </TabPanel>
              <TabPanel>
                <div className="about">
                  <div className="top_bar">
                    <p>Distance:580km</p>
                    <p>Estimated Time : 9.5 hrs</p>
                  </div>
                  <div className="detail">
                    <span>TOURIST ATTRACTIONS:</span>
                    <ul>
                      <li>Lal Tibba</li>
                      <li>Bhadraj Hill</li>
                      <li>Kempty Falls</li>
                      <li>Gun Hill</li>
                      <li>Shedup Choepelling Temple</li>
                      <li>Happy Valley</li>
                      <li>Library Bazaar</li>
                      <li>Jharipani Falls</li>
                      <li>Mussoorie Jheel</li>
                      <li>Mall Road Mussoorie</li>
                      <li>Nag Tibba</li>
                    </ul>
                    <Link to='/Range'>+ Explore more about Jaipur to Musoorie</Link>

                    <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                      BOOK A CAB
                    </button>
                  </div>
                </div>
                <Carousel
                  responsive
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                >
                  <div>
                    <img src={slider5} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider6} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider7} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider8} alt="Slider" />
                  </div>
                </Carousel>
              </TabPanel>
              <TabPanel>
                <div className="about">
                  <div className="top_bar">
                    <p>Distance:540km</p>
                    <p>Estimated Time : 12 hrs</p>
                  </div>
                  <div className="detail">
                    <span>TOURIST ATTRACTIONS:</span>
                    <ul>
                      <li>Hadimba temple</li>
                      <li>River Rafting</li>
                      <li>Zorbing</li>
                      <li>Paragliding</li>
                      <li>Gulaba</li>
                      <li>Solang Valley</li>
                      <li>Rohtang Pass</li>
                      <li>Mall Road Manali</li>
                      <li>Manali Gompa</li>
                      <li>Manikaran</li>
                      <li>Jana Waterfall</li>
                      <li>Rahala Waterfall</li>
                      <li>Vashisht Temple</li>
                      <li>Rozy Waterfall</li>
                      <li>Manu Temple</li>
                      <li>Skiing Trekking</li>
                    </ul>
                    <Link to='/Range'>+ Explore more about Delhi to Manali</Link>
                    <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                      BOOK A CAB
                    </button>
                  </div>
                </div>
                <Carousel
                  responsive
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                >
                  <div>
                    <img src={slider01} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider02} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider03} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider04} alt="Slider" />
                  </div>
                </Carousel>
              </TabPanel>
              <TabPanel>
                <div className="about">
                  <div className="top_bar">
                    <p>Distance:210km</p>
                    <p>Estimated Time : 5.5 hrs</p>
                  </div>
                  <div className="detail">
                    <span>TOURIST ATTRACTIONS:</span>
                    <ul>
                      <li>Har ki Pauri</li>
                      <li>Chandi Devi Temple</li>
                      <li>Ganga Aarti</li>
                      <li>Mansa Devi Temple</li>
                      <li>Chilla Wild life Sanctuary</li>
                      <li>Vaishno Devi Temple</li>
                      <li>Maya Devi Temple</li>
                      <li>Bharat Mata Mandir</li>
                      <li>Bada Bazar</li>
                      <li>Daksha Mahadev Temple</li>
                      <li>Sapt Rishi Ashram</li>
                      <li>Ardh Kumbh Mela</li>
                    </ul>
                    <Link to='/Range'>+ Explore more about Delhi to Haridwar</Link>
                    <button className="btn btn-primary btn-book-cab d-block my-3" onClick={scrollTop}>
                      BOOK A CAB
                    </button>
                  </div>
                </div>
                <Carousel
                  responsive
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                >
                  <div>
                    <img src={slider_5} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider_6} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider_7} alt="Slider" />
                  </div>
                  <div>
                    <img src={slider_8} alt="Slider" />
                  </div>
                </Carousel>
              </TabPanel>
            </Tabs>
          </div>
        </Row>
      </Col>
      </Row>

      <Row className="cashback light_bg border_left">
        <Col md={6} lg={6} className="mob-2">
          <div className="download">
            <img src={Mob} alt="car1" className="mob_img" />
          </div>
        </Col>
        <Col md={6} lg={6}>
          <div className="app">
            <p>Get ₹200 cashback on mobile app download</p>
            <div className="d-flex">
              <Link to="https://play.google.com/">
                <img src={playstore} alt="playstoore" />
              </Link>
              <Link to="https://apps.apple.com/">
                <img src={appstore} alt="Appstoore" />
              </Link>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="testmonial">
        <Col lg={12} className="bg-white border_right">
          <div className="m-md-5">
            <h2 className="mb-3 heading">Meet our Satisfied Customers</h2>
            <Tabs>
              <TabList>
                <Tab>
                  <img src={test1} alt="Testomonial" className="testo" />
                  <div className="profile_name">
                    <p>Sagar Gupta</p>
                    <span>New Delhi, Delhi</span>
                  </div>
                </Tab>
                <Tab>
                  <img src={test2} alt="Testomonial" className="testo" />
                  <div className="profile_name">
                    <p>Ankita Singh</p>
                    <span>Jaipur, Rajasthan</span>
                  </div>
                </Tab>
                <Tab>
                  <img src={test3} alt="Testomonial" className="testo" />
                  <div className="profile_name">
                    <p>Nisha Sharma</p>
                    <span>Surat, Gujrat</span>
                  </div>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="inner_test">
                  <Row>
                    <Col lg={4} md={3}>
                      <img src={test1} alt="Testomonial" className="testo1" />
                    </Col>
                    <Col lg={6} md={9}>
                      <div className="profile_name">
                        <p>
                          Booked a cab for my family and they were happy with
                          the service and I am very appreciative of all of your
                          support and help with this. Driver was very
                          cooperative and helpful. We’re satisfied. Your website
                          was clear and easy accessible also all calls were
                          answered immediately. The pick up and drop was on time
                          and perfect. We are happy with the service provided
                          and will definitely use it again if required. And also
                          recommend it to my friends and colleagues.
                        </p>
                        <p>Sagar Gupta</p>
                        <span>New Delhi, Delhi</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="inner_test">
                  <Row>
                    <Col lg={4} md={3}>
                      <img src={test2} alt="Testomonial" className="testo1" />
                    </Col>
                    <Col lg={6} md={9}>
                      <div className="profile_name">
                        <p>
                          The service was excellent- thankyou. My driver was
                          waiting at arrival for me with clear sign. He
                          introduced himself, was very polite and friendly and
                          drove me without delay. I will definitely use your
                          service again. Your service , and reliability , made a
                          long stressful journey end positively. Everything went
                          perfectly! I will be pleased to recommend this service
                          to my family and friends.
                        </p>
                        <p>Ankita Singh</p>
                        <span>Jaipur, Rajasthan</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="inner_test">
                  <Row>
                    <Col lg={4} md={3}>
                      <img src={test1} alt="Testomonial" className="testo1" />
                    </Col>
                    <Col lg={6} md={9}>
                      <div className="profile_name">
                        <p>
                          We hired the taxi services from CabRide for a week
                          during our Bangalore trip. The vehicle provided was a
                          good one and our driver Sajeeb was very helpful and a
                          reliable person. The pricing for our week long trip
                          for places nearby was reasonable amount. I would
                          definitely hired their services if I plan any further
                          visit to any other place. The support was very nice
                          and polite. Best wishes for CabRide.
                        </p>
                        <p>Nisha Sharma</p>
                        <span>Surat, Gujrat</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </Col>
      </Row>

      <Row className="partner light_bg border_left p-md-5 p-3">
        <h2 className="my-4 heading">Partner with us</h2>
        <Col lg={6} md={6}>
          <div className="mb-4">
            <h5>Attach your car (For Drivers)</h5>
            <p class="">
              Drivers / Taxi service providers can partner with CabRide to
              attach your car with company and get business from CabRide.
            </p>
            <button class="btn btn-primary">KNOW MORE</button>
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-4">
            <h5>Blog</h5>
            <p class="">
              Stay updated with travel related infomation with CabRide travel
              blogs. Interesting articles on travel tips, useful information,
              places, culture and cuisine.
            </p>
            <button class="btn btn-primary">READ BLOG</button>
          </div>
        </Col>

        <Col lg={6} md={6}>
          <div className="mb-4">
            <h5>Sales Partner</h5>
            <p>
              Become a Sales partner and earn with every booking placed through
              you. You can join as Affiliate, travel agent using our partner
              portal or API integration.
            </p>
            <button class="btn btn-primary">KNOW MORE</button>{" "}
            <button class="btn btn-primary">Partner Portal Login</button>
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-4">
            <h5 class="text-subtitle">Career</h5>
            <p class="text-left text-small">
              Interested in working at CabRide? We're looking for talent that
              will help us sustainably create value to tackle the future
              mobility challenge together.
            </p>
            <button class="btn btn-primary">KNOW MORE</button>
          </div>
        </Col>
      </Row>

      <Row className="media white_bg border_right p-md-5 p-3">
        <h2 className="my-4 heading">Media Coverage</h2>
        <Col lg={12}>
          <div className="slider-container">
            <Slider {...settings}>
              <Card>
                <img src={media1} alt="Slider1" />
                <h5>
                  Travel search and trends for Valentine’s Day decoded: Find out
                  where Indians travelled?
                </h5>
                <p className="media_info">
                  It is a common fuss not getting a cab when needed, especially
                  for intercity travel. Hurdles such as two-way charges,
                  last-minute cancellations and the monopoly of big vendors make
                  outstation cab booking a nightmare. The country has undergone
                  a massive digital transformation and yet still relied on local
                  cab vendors for their intercity travel needs ...
                </p>
                <p className="name">BW Marketing World</p>
                <span>BW Business World Publication</span>
              </Card>
              <Card>
                <img src={media2} alt="Slider2" />
                <h5>
                  How Cab Bazar is disrupting outstation travel segment
                  leveraging technology
                </h5>
                <p className="media_info">
                  Speaking to CIOL, Rishabh Gupta, Founder & CMO - CabRide, has
                  shared his views on how CabRide is providing both inter &
                  intra city One-way drops and round trips in Pan India. He
                  talked about his startup journey and also, and he speaks about
                  the growth plans. Cab Bazar is providing inter city One-way
                  drops and round trips in Pan India (25 states & 5 UTs) ...
                </p>
                <p className="name">CIOL</p>
                <span>CyberMedia Group</span>
              </Card>
              <Card>
                <img src={media3} alt="Slider3" />
                <h5>Google Campaigns - The Next New For CabRide</h5>
                <p className="media_info">
                  In a chat with BW Marketing World, Rishab Gupta, CMO - CabRide
                  talks about his expansion plans. We are focusing on Google
                  campaigns and spending a good amount on Google campaigns. We
                  are increasing the budget day by day to increase revenue and
                  increase sales. For the branding part, we are doing both
                  online and offline ...
                </p>
                <p className="name">ET Travel World</p>
                <span>The Times Group</span>
              </Card>
              <Card>
                <img src={media4} alt="Slider4" />
                <h5>
                  CabRide Disrupts Outstation Taxi Segment Projects To Generate
                  2.5x Growth
                </h5>
                <p className="media_info">
                  It is a common fuss not getting a cab when needed, especially
                  for intercity travel. Hurdles such as two-way charges,
                  last-minute cancellations and the monopoly of big vendors make
                  outstation cab booking a nightmare. The country has undergone
                  a massive digital transformation and yet still relied on local
                  cab vendors for their intercity travel needs ...
                </p>
                <p className="name">ET Travel World</p>
                <span>The Times Group</span>
              </Card>
            </Slider>
          </div>
        </Col>
      </Row>

      <Row className="contact light_bg border_left p-md-5 p-3">
        <Col lg={6} md={6}>
          <h2 className="my-4 heading">Get in touch</h2>
          <div className="mb-4 right_bar">
            <p className="send mb-4">
              Send us a message and we will get back to you as soon as possible!
            </p>
            <p class="sub_title">Call us at</p>
            <span class="mb-4">+91 9988776655</span>
            <p class="sub_title">Email us at</p>
            <span class="">support@cabride.com</span>
            <div className="social">
              <Link to="https://www.facebook.com/">
                <FaFacebook />
              </Link>
              <Link to="https://x.com/?lang=en">
                <FaTwitter />
              </Link>
              <Link to="https://in.linkedin.com/">
                <FaLinkedinIn />
              </Link>
              <Link to="https://www.instagram.com/">
                <FaInstagram />
              </Link>
              <Link to="https://www.youtube.com/">
                <FaYoutube />
              </Link>
            </div>
          </div>
        </Col>
        <Col lg={6} md={6}>
          <Form id="contact" className="mt-4" noValidate validated={validated} onSubmit={handleSubmit}>
            <div style={{ display: showSuccess ? "block" : "none" }}>
              <div className="info">
                <div className="description">
                  <h4 className="info-title">
                    Thanks! We will get back to you soon.
                  </h4>
                </div>
              </div>
            </div>
            <div style={{ display: showSuccess ? "none" : "block" }}>
              <Form.Group controlId="name">
                <label className="control-label">Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Enter your name"
                  required
                />
                <Form.Control.Feedback type="invalid"> Please Enter Name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <label className="control-label">Email</label>
                <input
                  type="email"
                  className="form-control mb-3"
                  placeholder="Enter your email id"
                  required
                />
                <Form.Control.Feedback type="invalid"> Please Enter Email</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label className="control-label">Phone</label>
                 <Form.Control
                  className="mobile mb-3"
                  type="text"
                  placeholder="Enter mobile number"
                  maxLength="10"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  required/>
                <Form.Control.Feedback type="invalid"> Please Enter Mobile Number</Form.Control.Feedback>
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
              </Form.Group>
              <Form.Group>
                <label className="control-label">Your message</label>
                <textarea
                  className="form-control mb-3"
                  rows={4}
                  placeholder="Tell us your thoughts and feelings..."
                  required
                />
              </Form.Group>
              <Row className="row">
                <Col md={12}>
                  <Button type="submit">SUBMIT</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
      </Container>
    </>
  );
}

export default Home;
